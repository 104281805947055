import { useEffect, useState } from 'react';

// eslint-disable-next-line no-shadow
enum State {
  TYPING,
  TYPING_IDLE,
  DELETING,
  DELETING_IDLE,
}

const TYPING_INTERVAL = 90;
const DELETING_INTERVAL = 90;
const TYPING_IDLE_INTERVAL = 1000;
const DELETING_IDLE_INTERVAL = 300;

const useTypingAnimation = (wordsToType: string[]) => {
  const [typedWord, setTypedWord] = useState(wordsToType[0]);
  const [typingState, setTypingState] = useState(State.TYPING_IDLE);
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    const nextTyped = wordsToType[wordIndex].slice(0, typedWord.length + 1);
    const nextDeleted = wordsToType[wordIndex].slice(0, typedWord.length - 1);

    let timeout: any;

    switch (typingState) {
      case State.TYPING:
        if (nextTyped === typedWord) {
          setTypingState(State.TYPING_IDLE);
        }
        timeout = setTimeout(() => {
          setTypedWord(nextTyped);
        }, TYPING_INTERVAL);
        break;
      case State.DELETING:
        if (!typedWord) {
          setTypingState(State.DELETING_IDLE);
        }
        timeout = setTimeout(() => {
          setTypedWord(nextDeleted);
        }, DELETING_INTERVAL);
        break;
      case State.TYPING_IDLE:
        timeout = setTimeout(() => {
          setTypingState(State.DELETING);
        }, TYPING_IDLE_INTERVAL);
        break;
      case State.DELETING_IDLE:
      default:
        timeout = setTimeout(() => {
          setWordIndex((wordIndex + 1) % wordsToType.length);
          setTypingState(State.TYPING);
        }, DELETING_IDLE_INTERVAL);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedWord, typingState]);

  return typedWord;
};

export default useTypingAnimation;
