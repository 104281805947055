import * as React from 'react';
import content from '../../content/home.yaml';
import PageSection from '../../components/PageSection';
import Contact from '../../components/home/Contact';
import Company from '../../components/home/Company';
import Join from '../../components/home/Join';
import Portfolio from '../../components/home/Portfolio';
import Services from '../../components/home/Services';
import Hero from '../../components/home/Hero';
import Customers from '../../components/home/Customers';
import DocumentHead from '../../components/DocumentHead';

type BodyPropsType = {
  viewRef: (node?: Element | null | undefined) => void;
};

const HomePage = ({ viewRef }: BodyPropsType) => {
  return (
    <div data-testid="home-container">
      <DocumentHead content={content.metadata} />

      <div className="" data-testid="hero">
        <Hero viewRef={viewRef} />
      </div>

      <PageSection
        className="md:mb-9 mb-7 mx-3 lg:mx-68px md:mx-64px"
        testId="portfolio-container"
      >
        <Portfolio />
      </PageSection>

      <PageSection
        className="md:pb-10 pb-9 lg:pt-0 md:pt-9 pt-7 lg:px-68px md:px-64px px-3"
        backgroundClass="md:bg-transparent bg-background md:bg-gradient-to-b from-background"
        testId="services-container"
      >
        <Services />
      </PageSection>

      <PageSection
        className="md:mb-11 mb-7 max:mx-68px"
        testId="company-container"
      >
        <Company />
      </PageSection>

      <PageSection className="md:mb-11 mb-9 mx-3" testId="customers-container">
        <Customers />
      </PageSection>

      <PageSection
        className="px-3 md:px-64px lg:px-68px md:mb-11 mb-10 pt-7 pb-9 md:pt-9 "
        testId="join-container"
        hasDefaultBackground
      >
        <Join />
      </PageSection>

      <PageSection className="md:mb-12 mb-10" testId="contact-container">
        <Contact />
      </PageSection>
    </div>
  );
};

export default HomePage;
