import { Link } from 'gatsby';
import * as React from 'react';
import { CTA } from 'types/content/common';
import { UIEventHandler, useRef, useState } from 'react';
import ScrollArrows from '../ScrollArrows';
import ContentImageTile from '../ContentImageTile';

import PortfolioCard from '../PortfolioCard';

type BodyPropsType = {
  cardData: Array<{
    overlay?: string;
    title: string;
    link?: string;
    image: { file: string; alt: string };
    logo: { file: string; alt: string };
    flipContent?: {
      desktop?: string;
      mobile?: string;
      cta?: CTA;
    };
  }>;
  baseImagePath: string;
  logoClass?: string;
};

const PortfolioMobileCards = ({
  cardData,
  baseImagePath,
  logoClass = '',
}: BodyPropsType) => {
  const [onScroll, setOnScroll] = useState<
    UIEventHandler<HTMLDivElement> | undefined
  >();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const cardClass = 'w-200px h-200px xs:w-255px xs:h-255px shadow-darker-md';

  return (
    <React.Fragment>
      <div
        data-testid="mobile-card-container"
        ref={containerRef}
        onScroll={onScroll}
        className="grid grid-cols-5-fr grid-flow-col gap-x-2.5 whitespace-nowrap overflow-x-scroll overflow-y-hidden scrollbar-none scroll-smooth"
      >
        {cardData.map(item => {
          return (
            <div ref={cardRef} data-testid="mobile-card" key={item.image.file}>
              {item.link && (
                <Link
                  data-testid="portfolio-card-mobile"
                  className="md:hidden"
                  to={item.link}
                >
                  <ContentImageTile
                    className={cardClass}
                    text={item.overlay}
                    imagePath={baseImagePath + item.image.file}
                    alt={item.image.alt}
                    logoPath={baseImagePath + item.logo.file}
                    logoAlt={item.logo.alt}
                    logoClass={logoClass}
                  />
                </Link>
              )}
              {!item.link && (
                <PortfolioCard
                  isAnimated
                  imagePath={baseImagePath + item.image.file}
                  alt={item.image.alt}
                  logoPath={baseImagePath + item.logo.file}
                  logoAlt={item.logo.alt}
                  className={cardClass}
                  flipData={item.flipContent}
                  logoClass={logoClass}
                  textClass={{
                    paragraph: 'hidden 2xs:block text-xs',
                    contactUsBtn:
                      'hidden xs:flex md:hidden xl:flex md:place-content-center xl:justify-start',
                    contactUsText:
                      'block 2xs:hidden md:block xl:hidden pointer-events-auto',
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      <ScrollArrows
        setOnScrollState={setOnScroll}
        elementGap={10}
        scrollContainer={containerRef.current}
        sampleElement={cardRef.current}
        className="w-full px-4"
        scrollType="visibleElements"
      />
    </React.Fragment>
  );
};

export default PortfolioMobileCards;
