import React from 'react';
import Carousel from '../Carousel';
import { homeContent } from '../../content/index';
import ContentImageTile from '../ContentImageTile';

const Customers = () => {
  const { title, cards } = homeContent.customers;

  const imageClass = 'object-contain';

  const cardClass = 'h-28 w-28 2xs:w-32 2xs:h-32 m-auto max:h-52 max:w-52';

  // The container layout for how each page should look like with a list of logos
  const mobileGridClass =
    'grid grid-cols-2 grid-rows-3 justify-items-center w-screen-width max-w-full';
  const tabletGridClass =
    'grid grid-cols-3 grid-rows-3 items-center justify-center w-screen-width max-w-full';

  // breakpoint for which carousel to render
  const mobileBreakPointClass = 'md:hidden';
  const tabletBreakPointClass = 'hidden md:grid';

  // allow the carousel to have an overflow horizontal container
  const carouselItemsClass =
    'whitespace-nowrap overflow-y-hidden mb-6 scroll-smooth scrollbar-none';

  const desktopCarouselItemsClass =
    'whitespace-nowrap overflow-y-hidden mb-6 scroll-smooth scrollbar-none';
  // Sum of logos should be mod 0 with number of pages
  const mobileLogosPerPage = 6;
  const tabletLogosPerPage = 9;

  // create the list of logos for mobile, tablet, desktop
  const logos = cards.map(item => (
    <div
      key={`card-${item.file}`}
      data-testid="card-container"
      className={cardClass}
    >
      <ContentImageTile
        imagePath={`../home/customers/${item.file}`}
        className="h-full w-full"
        imageClassName={imageClass}
        alt={item.alt}
      />
    </div>
  ));

  // create the list of logos per page
  function createArrayOfItemsPerPage(
    elementsPerPage: number,
    elements: Array<any>,
  ) {
    // calculate the page numbers
    const listOfPagesWithItems = [];
    const totalPages = elements.length / elementsPerPage;
    let startItemIndex = 0;
    let endItemIndex = elementsPerPage;

    // Distribute number of logos per page
    for (let i = 0; i < totalPages; i += 1) {
      listOfPagesWithItems.push(elements.slice(startItemIndex, endItemIndex));
      startItemIndex = endItemIndex;
      endItemIndex += elementsPerPage;
    }
    return listOfPagesWithItems;
  }

  const mobilePagesOfItemsToMap = createArrayOfItemsPerPage(
    mobileLogosPerPage,
    logos,
  );

  const tabletPagesOfItemsToMap = createArrayOfItemsPerPage(
    tabletLogosPerPage,
    logos,
  );

  return (
    <div className="space-y-5" data-testid="customersContainer">
      <div className="md:flex block justify-center">
        <div
          className="font-bold text-xl lg:text-2xl mx-auto"
          data-testid="customersHeader"
        >
          {title}
        </div>
      </div>
      <Carousel
        items={mobilePagesOfItemsToMap}
        itemClassName={mobileGridClass}
        itemContainerClassName={carouselItemsClass}
        className={mobileBreakPointClass}
      />
      <Carousel
        items={tabletPagesOfItemsToMap}
        itemClassName={tabletGridClass}
        itemContainerClassName={desktopCarouselItemsClass}
        className={tabletBreakPointClass}
      />
    </div>
  );
};
export default Customers;
