import React from 'react';
import SideImageContainer from '../SideImageContainer';
import { homeContent } from '../../content/index';
import Button from '../Button';

export default function Company() {
  const { header, description, cta, image } = homeContent.company;

  return (
    <div data-testid="sectionContainer">
      <SideImageContainer
        className="md:grid grid-cols-2 md:max-h-520px justify-center md-820px:flex"
        imageContainerClassName="h-520px w-full"
        data-testid="sectionImage"
        imagePath={`../../home/company/${image.file}`}
        imageAlt={image.alt}
      >
        <div className="md:flex flex-grow mx-3 md:mx-8 lg:mx-9 mt-6 md:my-6.5 md-820px:my-7 md:items-center">
          <div className="flex flex-col">
            <div className="md:w-267px md-820px:w-320px">
              <div
                data-testid="sectionHeader"
                className="text-lg lg:text-2xl font-semibold"
              >
                {header}
              </div>
              <div data-testid="sectionDescription" className="mt-4.2 md:mt-6">
                {description}
              </div>
              <div className="mt-7 md:mt-6.5 md:block flex">
                <Button
                  text={cta.desktop}
                  className="md:block hidden uppercase"
                  to="/company"
                />
                <Button
                  text={cta.mobile}
                  className="md:hidden block mx-auto uppercase"
                  to="/company"
                />
              </div>
            </div>
          </div>
        </div>
      </SideImageContainer>
    </div>
  );
}
