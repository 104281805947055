import classNames from 'classnames';
import React from 'react';
import { CTA } from 'types/content/common';
import PortfolioCard from './PortfolioCard';

type Card = {
  link?: string;
  overlay?: string;
  title: string;
  image: {
    file: string;
    alt: string;
  };
  logo: {
    file: string;
    alt: string;
  };
  flipContent?: {
    desktop?: string;
    mobile?: string;
    cta?: CTA;
  };
};

type LargeCardPropsType = {
  card: Card;
  baseImagePath: string;
  className?: string;
  cardClass?: string;
  logoClass?: string;
};

type SmallCardsPropsType = {
  cards: Card[];
  baseImagePath: string;
  className?: string;
  cardClass?: string;
  logoClass?: string;
};

type BodyPropsType = {
  type?: 'left' | 'right';
  largeCard: Card;
  smallCards: Card[];
  baseImagePath: string;
  largeClassName?: string;
  largeCardClassName?: string;
  smallClassName?: string;
  smallCardClassName?: string;
  logoClass?: string;
};

const LargeCard = ({
  card,
  baseImagePath,
  className = '',
  cardClass = '',
  logoClass = '',
}: LargeCardPropsType) => {
  return (
    <div
      data-testid="large-card-container"
      className={classNames(className, 'md:flex hidden w-full h-full')}
    >
      <PortfolioCard
        isAnimated
        link={card.link}
        overlay={card.overlay}
        imagePath={baseImagePath + card.image.file}
        alt={card.image.alt}
        logoPath={baseImagePath + card.logo.file}
        logoAlt={card.logo.alt}
        className={cardClass}
        logoClass={logoClass}
      />
    </div>
  );
};

export const SmallCards = ({
  cards,
  baseImagePath,
  className = '',
  cardClass = '',
  logoClass = '',
}: SmallCardsPropsType) => {
  return (
    <div
      data-testid="small-card-container"
      className="grid grid-cols-2 grid-rows-2 gap-y-3 gap-x-3"
    >
      {cards.map(card => {
        return (
          <div
            data-testid="small-card"
            key={card.image.file}
            className={classNames(className, 'md:block hidden h-full w-full')}
          >
            <PortfolioCard
              isAnimated
              link={card.link}
              imagePath={baseImagePath + card.image.file}
              alt={card.image.alt}
              logoPath={baseImagePath + card.logo.file}
              logoAlt={card.logo.alt}
              className={cardClass}
              flipData={card.flipContent}
              logoClass={logoClass}
            />
          </div>
        );
      })}
    </div>
  );
};

const PortfolioCardsContainer = ({
  type = 'left',
  largeCard,
  smallCards,
  baseImagePath,
  largeClassName = '',
  largeCardClassName = '',
  smallClassName = '',
  smallCardClassName = '',
  logoClass = '',
}: BodyPropsType) => {
  return (
    <div className="grid grid-cols-2 gap-x-3 w-full h-full">
      {type === 'left' && (
        <div className="">
          <LargeCard
            card={largeCard}
            baseImagePath={baseImagePath}
            className={largeClassName}
            cardClass={largeCardClassName}
            logoClass={logoClass}
          />
        </div>
      )}
      <div className="">
        <SmallCards
          cards={smallCards}
          baseImagePath={baseImagePath}
          className={smallClassName}
          cardClass={smallCardClassName}
          logoClass={logoClass}
        />
      </div>
      {type === 'right' && (
        <div className="">
          <LargeCard
            card={largeCard}
            baseImagePath={baseImagePath}
            className={largeClassName}
            cardClass={largeCardClassName}
            logoClass={logoClass}
          />
        </div>
      )}
    </div>
  );
};

export default PortfolioCardsContainer;
