import * as React from 'react';
import { Link } from 'gatsby';

type BodyPropsType = {
  stat: string;
  title: string;
  link: string;
  className?: string;
};

const HeroStatBlock = ({ title, stat, link, className }: BodyPropsType) => {
  return (
    <Link
      to={link}
      className={`
      flex 
      w-full
      h-full
      max-w-150px
      md:max-w-full
      md:w-150px
      md:h-11.5
      md-900px:w-190px
      md-900px:h-170px
      p-2 
      text-white 
      text-center 
      border 
      border-white 
      border-opacity-20 
      pointer-fine:hover:border-opacity-0 
      pointer-fine:hover:text-secondary 
      pointer-fine:hover:bg-white 
      pointer-fine:hover:bg-opacity-20 
      transition-all 
      duration-300 
      ${className}`}
    >
      <div data-testid="text-container" className="m-auto">
        <div data-testid="stat-value" className="text-2xl font-semibold">
          {title}
        </div>
        <div data-testid="stat-description" className="text-xs mb-1">
          {stat}
        </div>
      </div>
    </Link>
  );
};

export default HeroStatBlock;
