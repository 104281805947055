import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import Image from '../Image';

type BodyPropsType = {
  isAnimated?: boolean;
  title: string;
  tags: string[];
  description: string;
  link: string;
  file: string;
  alt: string;
};

const ServicesCard = ({
  isAnimated = false,
  title,
  tags,
  description,
  link,
  file,
  alt,
}: BodyPropsType) => {
  const imageStyles = classNames(
    'text-center justify-center flex flex-col w-full h-full',
    {
      transform: isAnimated,
      'duration-200': isAnimated,
      'ease-in-out': isAnimated,
      'pointer-fine:hover:scale-1.035': isAnimated,
    },
  );
  return (
    <div className="relative contents">
      <Link
        className="md:w-267px md:h-267px md:bg-white md:rounded-lg md:shadow-lg md:p-4"
        to={link}
      >
        <div className={imageStyles}>
          <div
            data-testid="services-card-img"
            className="hidden mb-2 md:inline-block"
          >
            <Image
              src={`../../../home/services/${file}`}
              alt={alt}
              className="h-68px mx-auto"
            />
          </div>
          <div
            data-testid="services-card-title"
            className="font-medium md:mb-0 text-sm xl:text-base"
          >
            {title}
          </div>
          <div
            data-testid="services-card-secondary"
            className="hidden mb-2 text-regalBlue text-xs font-normal md:block"
          >
            {tags.join(' • ')}
          </div>
          <div
            data-testid="services-card-description"
            className="hidden font-thin md:block md:text-xs xl:text-sm w-10/12 xl:w-full mx-auto"
          >
            {description}
          </div>
        </div>
      </Link>
    </div>
  );
};
export default ServicesCard;
