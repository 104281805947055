import React, { UIEventHandler, useRef, useState } from 'react';
import classNames from 'classnames';
import ScrollArrows from './ScrollArrows';
// import ScrollButtons from './ScrollButtons';

type BodyPropsType = {
  items?: Array<any>;
  itemClassName?: string;
  itemContainerClassName?: string;
  className?: string;
};

const Carousel = ({
  items = [],
  itemClassName = '',
  itemContainerClassName = '',
  className = '',
}: BodyPropsType) => {
  const [onScroll, setOnScroll] = useState<
    UIEventHandler<HTMLDivElement> | undefined
  >();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);

  const carouselClassName = classNames(className);
  const carouselContainerStyle = classNames(itemContainerClassName);
  const carouselItemsStyle = classNames(itemClassName);

  return (
    <section data-testid="carousel" className={carouselClassName}>
      <div
        data-testid="carousel-item-container"
        ref={containerRef}
        onScroll={onScroll}
        className={classNames(carouselContainerStyle)}
      >
        {items.map((item: any, index) => (
          <div
            className="inline-block max-w-full"
            ref={cardRef}
            key={`page - ${index + 1}`}
          >
            <div
              data-testid={`carousel-item-${index}`}
              className={`${carouselItemsStyle}
            }`}
            >
              {item}
            </div>
          </div>
        ))}
      </div>
      <ScrollArrows
        setOnScrollState={setOnScroll}
        elementGap={0}
        scrollContainer={containerRef.current}
        sampleElement={cardRef.current}
        className="w-full px-10%"
        scrollType="snapToElement"
      />
    </section>
  );
};

export default Carousel;
