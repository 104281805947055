import React from 'react';
import { homeContent } from '../../content/index';
import HeroStatBlock from './HeroStatBlock';
import useTypingAnimation from '../../hooks/useTypingAnimation';

type BodyPropsType = {
  viewRef: (node?: Element | null | undefined) => void;
};

const Hero = ({ viewRef }: BodyPropsType) => {
  const { header, cards, image } = homeContent.hero;

  const typedWord = useTypingAnimation(header.typings);

  return (
    <div
      ref={viewRef}
      data-testid="hero-container"
      className="lg:h-580px md:h-600px h-800px relative"
    >
      <div className="relative w-full h-full">
        <picture>
          <source
            media="(min-width: 768px)"
            srcSet={`home/hero/${image.desktop.webpFile}`}
            type="image/webp"
          />
          <source
            media="(max-width: 767px)"
            srcSet={`home/hero/${image.mobile.webpFile}`}
            type="image/webp"
          />
          <source
            media="(max-width: 767px)"
            srcSet={`home/hero/${image.mobile.file}`}
            type="image/png"
          />
          <img
            src={`home/hero/${image.desktop.file}`}
            alt={`${image.desktop.alt}`}
            className="absolute w-full h-full object-none 2xl:object-cover object-center-top-240px md:object-center-top-90px"
          />
        </picture>
      </div>
      <div className="absolute left-0 top-0 md:pt-190px pt-148px md:mx-10 sm:mx-6 ml-6 w-max md:text-6xl text-28px xs:text-40px md:tracking-wider font-bold text-white leading-normal">
        <div data-testid="title-line1" className="inline">
          {header.start}&nbsp;
        </div>
        <div className="inline absolute">{typedWord}</div>
        <div data-testid="title-line2" className="md:pt-25px">
          {header.line2}
        </div>
        <div data-testid="title-line3" className="md:pt-25px">
          {header.line3}
        </div>
      </div>
      <div className="absolute bottom-0 right-0 md:h-auto h-screen-width md:max-h-full max-h-375px grid grid-cols-3 grid-rows-3 gap-0">
        <div />
        <div />
        <HeroStatBlock
          className="border-b-0 border-r-0"
          title={cards.topRight.title}
          stat={cards.topRight.stat}
          link={cards.topRight.link}
        />
        <div />
        <HeroStatBlock
          className="border-r-0 border-b-0"
          title={cards.midLeft.title}
          stat={cards.midLeft.stat}
          link={cards.midLeft.link}
        />
        <HeroStatBlock
          className="border-b-0 border-r-0"
          title={cards.midRight.title}
          stat={cards.midRight.stat}
          link={cards.midRight.link}
        />
        <HeroStatBlock
          className="border-r-0 border-l border-b-0"
          title={cards.bottomLeft.title}
          stat={cards.bottomLeft.stat}
          link={cards.bottomLeft.link}
        />
        <HeroStatBlock
          className="border-r-0 border-b-0"
          title={cards.bottomRight.title}
          stat={cards.bottomRight.stat}
          link={cards.bottomRight.link}
        />
        <div className="border-l border-t border-white border-opacity-20" />
      </div>
    </div>
  );
};

export default Hero;
