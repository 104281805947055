import React from 'react';
import { homeContent } from '../../content/index';
import Button from '../Button';

function Contact() {
  const { header, contactCta } = homeContent.contact;

  return (
    <div className="mx-auto px-3" data-testid="contactContainer">
      <h2
        className="text-center text-xl font-bold font-sans lg:text-2xl tracking-tight"
        data-testid="contactHeader"
      >
        {header}
      </h2>
      <div className="justify-center md:flex md:flex-row mt-7">
        <div>
          <Button
            type="inverse"
            text={contactCta.desktop}
            className="md:block hidden uppercase mx-auto"
            to="/contact"
          />
          <Button
            type="inverse"
            text={contactCta.mobile}
            className="md:hidden block uppercase mx-auto"
            to="/contact"
          />
        </div>
      </div>
    </div>
  );
}

export default Contact;
