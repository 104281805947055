import classNames from 'classnames';
import { Link } from 'gatsby';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CTA } from 'types/content/common';
import Button from './Button';

import ContentImageTile from './ContentImageTile';

type BodyPropsType = {
  overlay?: string;
  link?: string;
  imagePath: string;
  alt: string;
  isAnimated?: boolean;
  logoPath?: string;
  logoAlt?: string;
  className?: string;
  flipData?: {
    desktop?: string;
    mobile?: string;
    cta?: CTA;
  };
  logoClass?: string;
  textClass?: {
    paragraph?: string;
    contactUsBtn?: string;
    contactUsText?: string;
  };
};

const PortfolioCard = ({
  isAnimated = true,
  link,
  overlay,
  imagePath,
  alt,
  logoPath = '',
  logoAlt = '',
  className = '',
  logoClass = '',
  flipData = undefined,
  textClass = undefined,
}: BodyPropsType) => {
  const [flip, setFlip] = useState(false);
  // eslint-disable-next-line no-undef
  const timeout = useRef<NodeJS.Timeout>();

  const handleFlipClick = () => {
    setFlip(!flip);
    if (timeout.current && flip) {
      clearTimeout(timeout.current);
    }
    if (!flip) {
      timeout.current = setTimeout(() => {
        setFlip(false);
      }, 10000);
    }
  };

  useEffect(() => {
    const timer = timeout.current;
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  });

  const parentStyling = className;
  const cardClass = 'rounded';
  const fadeInAnimation =
    'transition-opacity w-full overflow-hidden transform duration-500 ease-in opacity-100 h-full';
  const fadeOutAnimation =
    'transition-opacity w-full overflow-hidden transform duration-1000 ease-in opacity-0 h-0';
  const flipCardStyle = flip ? `outline-solid-1px-black-inset` : '';

  return (
    <div className="w-full h-full">
      <div className="flex flex-col w-full h-full">
        {link && (
          <Link
            data-testid="portfolio-card"
            className={classNames(
              'overflow-hidden shadow-darker-md rounded md:w-full md:h-full',
              parentStyling,
            )}
            to={link}
          >
            <div className="w-full h-full">
              <ContentImageTile
                className={classNames(cardClass, 'w-full h-full')}
                isAnimated={isAnimated}
                text={overlay}
                imagePath={imagePath}
                alt={alt}
                logoPath={logoPath}
                logoAlt={logoAlt}
                logoClass={logoClass}
                imageClassName="md:object-cover"
              />
            </div>
          </Link>
        )}
        {!link && (
          <div
            data-testid="portfolio-card"
            className={`md:w-full md:h-full overflow-hidden shadow-darker-md rounded box-border ${flipCardStyle} ${parentStyling}`}
            onClick={handleFlipClick}
            aria-hidden="true"
          >
            <div className="w-full h-full">
              <ContentImageTile
                className={classNames(
                  cardClass,
                  `${flip && fadeOutAnimation} ${!flip && fadeInAnimation}`,
                )}
                isAnimated={isAnimated}
                text={overlay}
                imagePath={imagePath}
                alt={alt}
                logoPath={logoPath}
                logoAlt={logoAlt}
                logoClass={logoClass}
                imageClassName="object-cover"
              />

              {flipData && (
                <div
                  data-testid="flipped-portfolio-card"
                  className={classNames(
                    'p-3 flex flex-col justify-center sm-400px:justify-between md:justify-center xl:justify-between',
                    `${!flip && fadeOutAnimation} ${flip && fadeInAnimation}`,
                  )}
                >
                  <div className="break-words whitespace-normal">
                    <p
                      className={
                        textClass?.paragraph ||
                        'text-xs xs-325px:text-sm md:text-xs max-1487px:text-sm hidden sm-400px:block md:hidden xl:block'
                      }
                    >
                      {flipData.desktop}
                    </p>
                  </div>
                  <div
                    className={
                      textClass?.contactUsBtn ||
                      'hidden sm-400px:flex md:hidden xl:flex md:justify-center md:place-items-center xl:justify-start'
                    }
                  >
                    <Button
                      data-testid="flipped-portfolio-card-cta"
                      text={flipData.cta?.desktop}
                      className="pointer-events-auto w-11.5 h-6.5"
                      to="/contact"
                    />
                  </div>
                  <div
                    className={
                      textClass?.contactUsText ||
                      'block sm-400px:hidden md:block xl:hidden pointer-events-auto text-xs 2xs-200px:text-sm 2xs:text-lg'
                    }
                  >
                    <div className="text-center md:text-xs lg:text-sm break-words whitespace-normal">
                      <Link
                        data-testid="flipped-portfolio-card-cta"
                        className="underline"
                        to="/contact"
                      >
                        {flipData.cta?.mobile}
                      </Link>
                      &nbsp;
                      <div className="hidden 2xs-165px:inline">
                        {flipData.mobile}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PortfolioCard;
