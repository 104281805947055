import * as React from 'react';
import { homeContent } from '../../content/index';
import Button from '../Button';
import PortfolioCardsContainer from '../PortfolioCardsContainer';
import PortfolioMobileCards from './PortfolioMobileCards';

const Portfolio = () => {
  const { header, cta, cards } = homeContent.portfolio;
  return (
    <div className="mt-8 md:space-y-0 space-y-7 md:mx-auto">
      <div className="md:mb-8 mb-7 text-lg md:text-xl lg:text-2xl md:text-center md:font-bold font-semibold">
        {header}
      </div>
      <div className="md:hidden block space-y-25px">
        <PortfolioMobileCards
          cardData={[cards.large, ...cards.small]}
          baseImagePath="../../home/"
          logoClass="w-11/12 h-11/12"
        />
      </div>
      <div className="md:block hidden justify-center">
        <PortfolioCardsContainer
          largeCard={cards.large}
          smallCards={cards.small}
          baseImagePath="../../home/"
          smallCardClassName="aspect-w-16 aspect-h-12"
        />
      </div>
      <div className="flex">
        <Button
          className="md:block hidden mt-7 mx-auto"
          text={cta.desktop}
          to="/portfolio"
        />
        <Button
          className="md:hidden mx-auto"
          text={cta.mobile}
          to="/portfolio"
        />
      </div>
    </div>
  );
};

export default Portfolio;
